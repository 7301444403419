import NextLink from 'next/link';
import { useMemo } from 'react';

import { APP_ROUTES } from '@/config/routing';
import { RoundedTile } from '@/core/ui/components/RoundedTile/RoundedTile';

export const PromotionTile = ({
  mobileImageUrl,
  title,
  backgroundColor,
  promotionKey,
}: {
  title: string;
  backgroundColor: string;
  mobileImageUrl: string;
  promotionKey: string;
}) => {
  const image = useMemo(
    () => ({
      alt: title,
      src: mobileImageUrl,
      priority: true,
    }),
    [mobileImageUrl, title]
  );

  const style = useMemo(
    () => ({
      backgroundColor,
    }),
    [backgroundColor]
  );

  return (
    <li>
      <NextLink href={APP_ROUTES.promotions(promotionKey)} shallow>
        <RoundedTile image={image} style={style}>
          {title}
        </RoundedTile>
      </NextLink>
    </li>
  );
};
