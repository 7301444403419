import { AccountMenuDesktop } from './components/AccountMenuDesktop/AccountMenuDesktop';
import { AccountMenuMobile } from './components/AccountMenuMobile/AccountMenuMobile';

export const AccountMenu = () => (
  <>
    <div className="xl:hidden">
      <AccountMenuMobile />
    </div>
    <div className="hidden xl:block">
      <AccountMenuDesktop />
    </div>
  </>
);
