import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { AUTH_ROLE } from '@/modules/auth/contexts/AuthContext';
import { useSession } from '@/modules/auth/hooks/useSession';
import { IconButton } from '@/modules/layout/components/IconButton/IconButton';
import UserIcon from '@/modules/layout/images/user-icon.svg';

const AccountMenuMobileDialog = dynamic(
  async () =>
    (
      await import(
        './components/AccountMenuMobileDialog/AccountMenuMobileDialog'
      )
    ).AccountMenuMobileDialog,
  { ssr: false }
);

export const AccountMenuMobile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const authRole = useSession('authRole');
  const router = useRouter();

  const handleClick = async () => {
    if (authRole === AUTH_ROLE.AUTHENTICATED) {
      await router.push('/profile/your-delio');
    } else {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <UserIcon className="size-6" data-testid="user-icon" />
      </IconButton>
      {isOpen && (
        <AccountMenuMobileDialog open={isOpen} onClose={handleClose} />
      )}
    </>
  );
};
