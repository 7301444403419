import dynamic from 'next/dynamic';

import { useSession } from '@/modules/auth/hooks/useSession';

import { AccountMenuDesktopPopover } from './components/AccountMenuDesktopPopover/AccountMenuDesktopPopover';

const AccountMenuDesktopAuthenticatedContent = dynamic(
  async () =>
    (
      await import(
        './components/AccountMenuDesktopAuthenticatedContent/AccountMenuDesktopAuthenticatedContent'
      )
    ).AccountMenuDesktopAuthenticatedContent,
  { ssr: false }
);

const AccountMenuDesktopUnauthenticatedContent = dynamic(
  async () =>
    (
      await import(
        './components/AccountMenuDesktopUnauthenticatedContent/AccountMenuDesktopUnauthenticatedContent'
      )
    ).AccountMenuDesktopUnauthenticatedContent,
  { ssr: false }
);

export const AccountMenuDesktop = () => {
  const authRole = useSession('authRole');
  const isAuthenticated = authRole === 'AUTHENTICATED';

  return (
    <div>
      <AccountMenuDesktopPopover>
        {isAuthenticated ? (
          <AccountMenuDesktopAuthenticatedContent />
        ) : (
          <AccountMenuDesktopUnauthenticatedContent />
        )}
      </AccountMenuDesktopPopover>
    </div>
  );
};
