import { CategoryLandingBanners } from '@/modules/categoryLanding/queries/types';
import { HomepageBanners as HomeBanners } from '@/modules/home/queries/types';

type Banners = CategoryLandingBanners | HomeBanners;
type Props = {
  banners: Banners;
};

export const useBanners = ({ banners }: Props) =>
  banners
    .map((banner) => ({
      __typename: banner.__typename,
      desktopImageUrl: banner.desktopImageUrl,
      id: banner.id,
      mobileImageUrl: banner.mobileImageUrl,
      replaceIn: banner.replaceIn,
      title: banner.title,
      link: getLink(banner),
    }))
    .filter((el) => el.desktopImageUrl && el.mobileImageUrl);

const getLink = (banner: NonNullable<Banners[number]>) => {
  if ('link' in banner) {
    return banner.link;
  }

  if ('promotion' in banner) {
    if (banner.promotion.ancestor) {
      return `/promotions/${banner.promotion.ancestor.key}/${banner.promotion.key}`;
    }
    return `/promotions/${banner.promotion.key}`;
  }

  if ('category' in banner) {
    if (banner.category.ancestors.length) {
      const ancestorsLink = banner.category.ancestors.reduce(
        (acc, ancestor) => `${acc}/${ancestor.key}`,
        `/categories`
      );
      return `${ancestorsLink}/${banner.category.key}`;
    }
    return `/categories/${banner.category.key}`;
  }

  return undefined;
};
