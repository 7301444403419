import { useBanners } from '@/core/hooks/useBanners';
import { BannersCarousel } from '@/core/ui/components/Banners/components/BannersCarousel';

import { HomepageBannersSection } from '../../queries/types';

export const BannersSection = ({
  section,
}: {
  section: HomepageBannersSection;
}) => {
  const banners = useBanners({ banners: section.banners });

  return <BannersCarousel banners={banners} priorityFirstSlide />;
};
