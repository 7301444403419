import Image, { ImageProps } from 'next/image';
import { CSSProperties, ReactNode } from 'react';

import { cn } from '@/core/ui/utils';

export const RoundedTile = ({
  style,
  children,
  isSelected,
  onSelect,
  image,
}: {
  style?: CSSProperties;
  children?: ReactNode;
  isSelected?: boolean;
  onSelect?: () => void;
  image: ImageProps;
}) => (
  <div
    className={cn(
      'relative',
      'overflow-hidden',
      'w-[106px]',
      'h-[124px]',
      'rounded-xl',
      isSelected ? 'mt-4' : 'mt-0',
      'transition-all',
      'duration-200'
    )}
    style={style}
  >
    <button
      className="flex h-full p-3 text-left text-sm font-medium text-gray-750"
      onClick={onSelect}
      type="button"
    >
      <Image
        {...image}
        width={IMAGE_WIDTH}
        height={IMAGE_HEIGHT}
        className="absolute bottom-0 right-0 object-contain object-right"
        style={{ maxHeight: IMAGE_HEIGHT }}
      />
      {children}
    </button>
  </div>
);

export const IMAGE_WIDTH = 106;
export const IMAGE_HEIGHT = 72;
