import Image from 'next/image';

import { TextAndImageSection as TextAndImageSectionType } from '@/core/types';

import { TextAndImagePortableText } from './components/TextAndImagePortableText';

interface TextAndImageSectionProps {
  section: TextAndImageSectionType;
}

const IMAGE_WIDTH = 228;
const IMAGE_HEIGHT = 410;

export const TextAndImageSection = ({
  section: {
    textRaw,
    title,
    webImage: { desktopImageUrl },
  },
}: TextAndImageSectionProps) => (
  <div className="mx-5 mt-8 flex items-start rounded-xl bg-gray-050 px-5 pb-5 md:px-6 lg:mb-4 lg:mt-20 lg:pb-12 lg:pl-0 lg:pr-18 xl:mx-auto xl:max-w-7xl">
    <div className="mx-16 mt-14 hidden flex-[1_0_20%] lg:block">
      <Image
        src={desktopImageUrl}
        alt={title}
        width={IMAGE_WIDTH}
        height={IMAGE_HEIGHT}
        quality={100}
        className="h-auto w-full"
      />
    </div>
    <div className="mt-5 flex flex-col font-sans text-sm text-gray-900 lg:mt-14">
      <h4 className="text-lg font-medium leading-6 md:text-2xl">{title}</h4>
      <TextAndImagePortableText content={textRaw} />
    </div>
  </div>
);
