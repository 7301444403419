import { Trans, useTranslation } from 'next-i18next';
import { memo } from 'react';
import { useContextSelector } from 'use-context-selector';

import { Badge } from '@/core/ui/components/Badge/Badge';
import { BundleBadge } from '@/core/ui/components/BundleBadge/BundleBadge';
import { FormattedNetContain } from '@/core/ui/components/FormattedNetContain/FormattedNetContain';
import { FormattedPrice } from '@/core/ui/components/FormattedPrice/FormattedPrice';
import { OldPrice } from '@/core/ui/components/OldPrice/OldPrice';
import { ProductBadgesList } from '@/core/ui/components/ProductBadgesList/ProductBadgesList';
import { ProductImage } from '@/core/ui/components/ProductImage/ProductImage';
import { ProductMultibuyBadge } from '@/core/ui/components/ProductMultibuyBadge/ProductMultibuyBadge';
import { cn } from '@/core/ui/utils';
import { ProductCounter } from '@/modules/product/components/ProductCounter/ProductCounter';
import { ProductSearchContext } from '@/modules/product/context/ProductSearchContext';
import { checkPriceDifference } from '@/modules/product/services/checkPriceDifference';
import { FC, FCC } from '@/types/common';

type MobileSuggestedProductProps = {
  index: number;
};

export const MobileSuggestedProduct: FC<MobileSuggestedProductProps> = memo(
  ({ index }) => {
    const { t } = useTranslation('product');
    const { suggestedProducts, pushToProductPage, attributionToken } =
      useContextSelector(
        ProductSearchContext,
        ({ suggestedProducts, pushToProductPage, attributionToken }) => ({
          suggestedProducts,
          pushToProductPage,
          attributionToken,
        })
      );

    const product = suggestedProducts?.[index];

    if (!product) return null;

    const discount = product.price?.discounted;
    const [imageUrl = ''] = product.imagesUrls;

    const pricePercentageDifference = checkPriceDifference(
      discount?.discount.value
    );

    const isOnStock = product?.availableQuantity > 0 && product.isPublished;

    return (
      <li className={cn('border-b border-border-subtle')}>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => pushToProductPage(index)}
          onClick={() => pushToProductPage(index)}
          className={cn('flex', 'p-4', 'pl-3', 'w-full', 'relative')}
        >
          <div
            className={cn(
              'min-w-20',
              'w-20',
              'h-20',
              'mr-3',
              !isOnStock && 'grayscale'
            )}
          >
            <ProductImage
              alt={product.name}
              url={imageUrl}
              width={80}
              height={80}
              size="small"
            />
          </div>
          <div
            className={cn(
              'flex',
              'flex-1',
              'flex-col',
              'mr-5',
              !isOnStock && 'text-text-disabled'
            )}
          >
            <TruncatedName>{product.name}</TruncatedName>
            <div className={cn('flex', 'flex-col', 'mr-5', 'flex-1')}>
              <span
                className={cn(
                  'text-body-sm',
                  'text-text-subtle',
                  'text-left',
                  'mt-1',
                  'mb-1.5'
                )}
              >
                <FormattedNetContain
                  containUnit={product.attributes.contain_unit}
                  netContain={product.attributes.net_contain}
                />
              </span>

              <div className={cn('flex', 'gap-x-1.5', 'mt-auto')}>
                {pricePercentageDifference.discountType ? (
                  <Badge variant={isOnStock ? 'default' : 'disabled'}>
                    {pricePercentageDifference.discountType === 'PERCENTAGE' ? (
                      <Trans
                        ns="product"
                        i18nKey="-{{value}}%"
                        values={{
                          value: Math.ceil(
                            pricePercentageDifference.difference
                          ),
                        }}
                      />
                    ) : (
                      <FormattedPrice
                        hideDecimals
                        centAmount={pricePercentageDifference.difference}
                        currencyCode={pricePercentageDifference.currencyCode}
                      />
                    )}
                  </Badge>
                ) : null}
                {product.productType === 'complex-bundle' && (
                  <BundleBadge disabled={!isOnStock} />
                )}
                <div className={cn('flex', 'items-center', 'gap-x-1')}>
                  {isOnStock && product?.price?.multibuy?.description && (
                    <ProductMultibuyBadge
                      variant={
                        product?.price?.multibuy?.maxQuantity
                          ? 'secondary'
                          : 'primary'
                      }
                      description={product.price.multibuy.description}
                    />
                  )}
                  <ProductBadgesList
                    tags={product.attributes.tags}
                    direction="row"
                    maxNumberOfBadgesOnDesktop={3}
                    maxNumberOfBadgesOnMobile={2}
                    showOnlyIconOnMobile
                    slim
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={cn('flex', 'flex-col', 'items-end')}>
            <ProductCounter
              availableQuantity={product.availableQuantity}
              sku={product.sku}
              isPublished={product.isPublished}
              attributionToken={attributionToken}
            />
            <div
              className={cn(
                'flex',
                'flex-col',
                'absolute',
                'right-4',
                discount ? 'bottom-5' : 'bottom-4'
              )}
            >
              <div
                className={cn(
                  'flex',
                  'text-body-md',
                  'font-medium',
                  'items-center',
                  discount ? 'text-primary-400' : 'text-text-default'
                )}
              >
                <FormattedPrice
                  centAmount={
                    discount
                      ? discount.value.centAmount
                      : product.price?.value.centAmount
                  }
                  currencyCode={
                    discount
                      ? discount.value.currencyCode
                      : product.price?.value.currencyCode
                  }
                />
                {discount && (
                  <span
                    className={cn(
                      'text-text-subtlest',
                      'text-caption-lg',
                      'font-normal',
                      'ml-2'
                    )}
                  >
                    <OldPrice
                      centAmount={product.price?.value.centAmount}
                      currencyCode={product.price?.value.currencyCode}
                      color=""
                    />
                  </span>
                )}
              </div>
              {discount && (
                <span
                  className={cn(
                    'text-caption-sm',
                    'text-text-subtlest',
                    'mt-1',
                    'text-right'
                  )}
                >
                  {t('lowest price')}
                </span>
              )}
            </div>
          </div>
        </div>
      </li>
    );
  }
);

MobileSuggestedProduct.displayName = 'MobileSuggestedProduct';

const TruncatedName: FCC = ({ children }) => (
  <div
    className={cn(
      'text-left',
      'text-body-md',
      'font-medium',
      'line-clamp-2',
      'h-10'
    )}
  >
    {children}
  </div>
);
