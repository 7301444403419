import { useMemo } from 'react';

import {
  NATURAL_DESKTOP_BANNER_HEIGHT,
  NATURAL_DESKTOP_BANNER_WIDTH,
  NATURAL_MOBILE_BANNER_HEIGHT,
  NATURAL_MOBILE_BANNER_WIDTH,
} from '@/core/constants/bannerDimensions';
import { MOBILE_SLIDE_SPACING } from '@/core/constants/sliders';
import { useSlider } from '@/core/hooks/useSlider';
import { BannersArrows } from '@/core/ui/components/BannersArrows/BannersArrows';
import { BannersDots } from '@/core/ui/components/BannersDots/BannersDots';

import { BannersCarouselSlide } from './BannersCarouselSlide';

export const BannersCarousel = ({
  banners,
  desktopNaturalBannerWidth,
  priorityFirstSlide,
}: {
  readonly banners: {
    mobileImageUrl: string;
    desktopImageUrl: string;
    replaceIn?: number | null;
    link?: string;
    id: string;
    title: string;
    __typename: string;
  }[];
  desktopNaturalBannerWidth?: number;
  priorityFirstSlide?: boolean;
}) => {
  const durations = useMemo(
    () => banners?.map(({ replaceIn }) => replaceIn),
    [banners]
  );

  const {
    sliderRef,
    showControls,
    handleNext,
    handlePrev,
    handleMouseEnter,
    handleMouseLeave,
    currentSlide,
    handleDotClick,
    dotsLength,
  } = useSlider({
    breakpoints: {
      '(min-width: 768px)': {
        slides: { spacing: 0, perView: 1 },
      },
    },
    slides: {
      perView: 1,
      spacing: MOBILE_SLIDE_SPACING,
    },
    loop: banners.length > 1,
    initial: 0,
    durations,
  });

  return (
    <>
      <div className="relative">
        <div className="keen-slider" ref={sliderRef}>
          {banners.map((bannersProps, index) => (
            <BannersCarouselSlide
              desktopSrc={bannersProps.desktopImageUrl}
              mobileSrc={bannersProps.mobileImageUrl}
              naturalSlideHeightDesktop={NATURAL_DESKTOP_BANNER_HEIGHT}
              naturalSlideWidthDesktop={
                desktopNaturalBannerWidth || NATURAL_DESKTOP_BANNER_WIDTH
              }
              naturalSlideHeightMobile={NATURAL_MOBILE_BANNER_HEIGHT}
              naturalSlideWidthMobile={NATURAL_MOBILE_BANNER_WIDTH}
              link={
                'link' in bannersProps && bannersProps.link
                  ? bannersProps.link
                  : null
              }
              key={bannersProps.id}
              index={index}
              title={bannersProps.title}
              type={bannersProps.__typename}
              priorityFirstSlide={priorityFirstSlide}
            />
          ))}
        </div>
        {showControls && (
          <BannersArrows
            onPrev={handlePrev}
            onNext={handleNext}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        )}
      </div>
      {showControls && dotsLength && (
        <BannersDots
          currentSlide={currentSlide}
          totalSlides={dotsLength}
          onClick={handleDotClick}
        />
      )}
    </>
  );
};
