import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { detectSafariBrowser } from './useIsSafariBrowser';

/**
 * @deprecated The useMedia should not be used. Use useMediaQuery from react-responsive instead
 */
export const useMedia = () => {
  const [isOnClient, setIsOnClient] = useState(false);
  const [windowInnerWidth, setWindowWidth] = useState<null | number>(null);

  const updateWindowWidth = useDebouncedCallback(
    useCallback(
      () =>
        setWindowWidth(
          detectSafariBrowser()
            ? document.documentElement.clientWidth || document.body.clientWidth
            : window.innerWidth
        ),
      []
    ),
    DEBOUNCE_MS
  );

  useIsomorphicEffect(() => {
    setIsOnClient(true);
    updateWindowWidth();
    const resizeObserver = new ResizeObserver(updateWindowWidth);
    resizeObserver.observe(window.document.body);
    return () => resizeObserver.unobserve(window.document.body);
  }, [updateWindowWidth]);

  return {
    windowInnerWidth,
    isOnClient,
  };
};

const useIsomorphicEffect =
  typeof window === 'undefined' ? useEffect : useLayoutEffect;

const DEBOUNCE_MS = 300;
