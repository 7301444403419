import Image from 'next/image';
import { memo, useState } from 'react';

import ProductImageFallback from '@/core/images/product-image-placeholder.svg';
import { FC } from '@/types/common';
import { ImageSize, withImageSize } from '@lib/api/with-image-size';

import { Skeleton } from '../Skeleton/Skeleton';

const PRODUCT_TILE_SIZE = 92;

export const ProductImage: FC<ProductImageProps> = memo(
  ({ alt, url, height, width, size = 'small', quality, sizes }) => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleError = () => {
      setError(true);
    };

    if (error || !url) {
      return (
        <ProductImageFallback
          width={width || PRODUCT_TILE_SIZE}
          height={height || PRODUCT_TILE_SIZE}
          className="text-primary-200"
        />
      );
    }

    const originSrc = withImageSize(url, size);

    return (
      <div
        style={{
          width: width || PRODUCT_TILE_SIZE,
          height: height || PRODUCT_TILE_SIZE,
        }}
        className="relative flex items-center justify-center"
      >
        {loading && (
          <Skeleton
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            width={width || PRODUCT_TILE_SIZE}
            height={height || PRODUCT_TILE_SIZE}
          />
        )}
        <Image
          alt={alt}
          src={originSrc}
          fill
          onError={handleError}
          quality={quality || DEFAULT_IMAGE_QUALITY}
          sizes={sizes || `${width || PRODUCT_TILE_SIZE}px`}
          onLoad={() => setLoading(false)}
          className="object-contain"
        />
      </div>
    );
  }
);

type ProductImageProps = {
  url?: string;
  alt: string;
  width?: number;
  height?: number;
  size?: ImageSize;
  quality?: number;
  sizes?: string;
};

const DEFAULT_IMAGE_QUALITY = 80;
