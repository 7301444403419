import Link from 'next/link';
import { useMemo } from 'react';

import { cn } from '@/core/ui/utils';
import { sendBannerSelectEvent } from '@/modules/analytics/eventHandlers/sendBannerSelectEvent';
import { breakpoints } from '@lib/theme/config/breakpoints';
import { useMedia } from '@lib/theme/hooks/useMedia';

import { BannersCarouselSlideImage } from './BannersCarouselSlideImage';

export const BannersCarouselSlide = ({
  type,
  link,
  title,
  desktopSrc,
  mobileSrc,
  naturalSlideWidthDesktop,
  naturalSlideHeightDesktop,
  naturalSlideWidthMobile,
  naturalSlideHeightMobile,
  index,
  priorityFirstSlide,
}: BannersCarouselSlideProps) => {
  const { windowInnerWidth } = useMedia();
  const shouldPriorityFirstSlide = priorityFirstSlide && index === 0;

  const image = useMemo(
    () => (
      <BannersCarouselSlideImage
        title={title}
        desktopSrc={desktopSrc}
        mobileSrc={mobileSrc}
        naturalSlideWidthDesktop={naturalSlideWidthDesktop}
        naturalSlideHeightDesktop={naturalSlideHeightDesktop}
        naturalSlideWidthMobile={naturalSlideWidthMobile}
        naturalSlideHeightMobile={naturalSlideHeightMobile}
        loading={shouldPriorityFirstSlide ? 'eager' : 'lazy'}
        fetchPriority={shouldPriorityFirstSlide ? 'high' : undefined}
        priority={shouldPriorityFirstSlide ? true : undefined}
      />
    ),
    [
      desktopSrc,
      mobileSrc,
      naturalSlideHeightDesktop,
      naturalSlideHeightMobile,
      naturalSlideWidthDesktop,
      naturalSlideWidthMobile,
      shouldPriorityFirstSlide,
      title,
    ]
  );

  const handleClick = () => {
    sendBannerSelectEvent({
      position: index + 1,
      type,
      link,
      title,
      backgroundImage:
        Number(windowInnerWidth) < breakpoints.sm ? mobileSrc : desktopSrc,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div
      tabIndex={0}
      role="button"
      key={title}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      className={cn(
        'keen-slider__slide',
        'min-w-full',
        'rounded-lg',
        'sm:rounded-2xl'
      )}
    >
      {link ? <Link href={link}>{image}</Link> : image}
    </div>
  );
};

export type BannersCarouselSlideProps = {
  type: string;
  link: string | null | undefined;
  index: number;
  title: string;
  desktopSrc: string;
  mobileSrc: string;
  naturalSlideWidthDesktop: number;
  naturalSlideHeightDesktop: number;
  naturalSlideWidthMobile: number;
  naturalSlideHeightMobile: number;
  priorityFirstSlide?: boolean;
};
