import Image from 'next/image';

export const BannersCarouselSlideImage = ({
  desktopSrc,
  mobileSrc,
  title,
  naturalSlideWidthDesktop,
  naturalSlideHeightDesktop,
  naturalSlideWidthMobile,
  naturalSlideHeightMobile,
  loading,
  fetchPriority,
  priority,
}: BannersCarouselSlideImageProps) => (
  <>
    <Image
      className="block sm:hidden"
      width={naturalSlideWidthMobile}
      height={naturalSlideHeightMobile}
      alt={title}
      loading={priority ? undefined : loading}
      fetchPriority={fetchPriority}
      src={mobileSrc}
      style={{ width: '100%', height: 'auto' }}
      priority={priority}
    />
    <Image
      className="hidden max-h-[260px] sm:block"
      width={naturalSlideWidthDesktop}
      height={naturalSlideHeightDesktop}
      alt={title}
      loading={priority ? undefined : loading}
      fetchPriority={fetchPriority}
      src={desktopSrc}
      style={{ maxHeight: naturalSlideHeightDesktop }}
      priority={priority}
    />
  </>
);

export type BannersCarouselSlideImageProps = {
  title: string;
  desktopSrc: string;
  mobileSrc: string;
  naturalSlideWidthDesktop: number;
  naturalSlideHeightDesktop: number;
  naturalSlideWidthMobile: number;
  naturalSlideHeightMobile: number;
  loading?: 'lazy' | 'eager';
  fetchPriority?: 'high' | 'low' | 'auto';
  priority?: boolean;
};
